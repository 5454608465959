import Container from "components/Container";
import DoodleCard from "components/DoodleCard";
import Header from "components/Header";
import Layout from "components/Layout";
import { graphql } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import Helmet from "react-helmet";

const DoodlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default ({
  data: {
    doodles: { edges: doodleEdges },
  },
}) => (
  <Layout>
    <Helmet title={"Doodles"} />
    <Header title="Doodles">
      Doodles are visual experimentations created in a short amount of time
    </Header>
    <Container>
      <DoodlesWrapper>
        {doodleEdges.map(doodle => (
          <DoodleCard
            key={doodle.node.fields.slug}
            path={doodle.node.fields.slug}
            title={doodle.node.frontmatter.title}
            headline={doodle.node.frontmatter.headline}
          />
        ))}
      </DoodlesWrapper>
    </Container>
  </Layout>
);

export const pageQuery = graphql`
  query {
    doodles: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "doodles" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            headline
          }
        }
      }
    }
  }
`;
