import Link from "components/Link";
import { rgba } from "polished";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.article``;

const StyledLink = styled(Link)`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 20px ${rgba("#c0c0c0", 0.5)};
  display: flex;
  color: inherit;
  display: block;
  height: 100%;
  padding: 2rem;
  text-decoration: none;
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 15px 20px #c0c0c0;
    transform: translateY(-5px);
  }
`;

const DoodleTitle = styled.h2`
  margin-bottom: 0.25rem;
`;

const DoodleDate = styled.p`
  margin-bottom: 1rem;
`;

const DoodleHeadline = styled.p`
  flex: auto;
  margin-bottom: 0;
`;

export default ({ title, headline, path, date }) => (
  <Wrapper>
    <StyledLink to={`${path}/index.html`} target="_blank">
      <header>
        <DoodleTitle>{title}</DoodleTitle>
        <DoodleDate>{date}</DoodleDate>
      </header>
      <DoodleHeadline>{headline}</DoodleHeadline>
    </StyledLink>
  </Wrapper>
);
